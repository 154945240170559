<template>
    <div class="page bg-white" id="reorganize">
        <el-row :gutter="20">
            <el-col :span="6">
                <div class="lump">
                    <div class="catalogueBox">
                        <div class="flex_l_c">
                            <el-input class="flex1" maxlength="66" v-model="catalogueSrh" clearable placeholder="请输入展览名称" size="small"></el-input>
                        </div>
                        <el-table
                                :data="catalogueList"
                                ref="catalogueTable"
                                size="small"
                                height="calc(100vh - 268px)"
                                class="table" highlight-current-row
                                @current-change="handleCurrentChange">
                            <el-table-column prop="exhibitionName" label="展览名称" show-overflow-tooltip/>
                        </el-table>
                        <el-pagination
                                @size-change="sizeChangeHandle"
                                @current-change="currentChangeHandle"
                                :current-page="pageNo"
                                :page-sizes="[10, 20, 50, 100]"
                                :page-size="pageSize"
                                :total="total"
                                background
                                layout="prev, pager, next">
                        </el-pagination>
                    </div>
                </div>
            </el-col>
            <el-col :span="18">
                <div class="lump">
                    <el-row class="headBox">
                        <el-col :span="10">
                            <div class="flex_l_c">
                                <el-input class="flex1 m_r1" v-model="fileSrh" maxlength="66" placeholder="请输入目录名" size="small"></el-input>
                                <el-button icon="el-icon-search" type="primary" size="small" @click="searchList">查询</el-button>
                                <el-button @click="resetSearch()" size="small" icon="el-icon-refresh-right">重置</el-button>
                            </div>
                        </el-col>
                        <el-col :span="14" class="text_right">
                            <el-button icon="el-icon-plus" size="small" type="primary":disabled="exhibitionId==''" @click="view('add', exhibitionId)">新建</el-button>
                            <el-button size="small" type="primary" :disabled="exhibitionId=='' || fileList.length==0" @click="addTemplate">设为模板</el-button>
                            <el-button size="small" type="primary"  :disabled="exhibitionId==''" @click="useTemplate">套用模板</el-button>
                        </el-col>
                    </el-row>
                    <div class="catalogueBox">
                        <el-table
                                :data="fileList"
                                size="small"
                                @selection-change="selectionChangeHandle"
                                v-loading="loading"
                                height="calc(100vh - 290px)"
                                class="table">
                            <el-table-column type="selection" width="50"/>
                            <el-table-column prop="sortNumber" label="排序" width="110"></el-table-column>
                            <el-table-column prop="directoryNo" label="目录编号" show-overflow-tooltip/>
                            <el-table-column prop="directoryName" label="目录名" show-overflow-tooltip/>
                            <el-table-column prop="publishState" label="发布状态" show-overflow-tooltip>
                                <template slot-scope="scope">
                                    <el-tag :type="scope.row.publishState == 0?'success':'danger'" >{{scope.row.publishState == 0 ? '已发布' : '未发布'}}</el-tag>
                                </template>
                            </el-table-column>
                            <el-table-column fixed="right" label="操作" width="200">
                                <template slot-scope="scope">
                                    <el-button size="mini" type="primary" plain @click="view('edit', scope.row)">编辑
                                    </el-button>
                                    <el-button type="danger" size="mini" @click="del(scope.row.id)">删除</el-button>
                                </template>
                            </el-table-column>
                        </el-table>
                        <el-pagination
                                @size-change="sizeChangeHandle2"
                                @current-change="currentChangeHandle2"
                                :current-page="pageNo2"
                                :page-sizes="[10, 20, 50, 100]"
                                :page-size="pageSize2"
                                :total="total2"
                                background
                                layout="total, prev, pager, next, sizes">
                        </el-pagination>
                    </div>
                </div>
            </el-col>
        </el-row>
        <!--新建、编辑目录-->
        <AddCatalogueForm ref="addCatalogueForm" @refreshDataList="getFileList"></AddCatalogueForm>
        <!--新建模板-->
        <AddTemplate ref="addTemplate" @refreshDataList="getFileList"></AddTemplate>
        <!--套用模板-->
        <UseTemplate ref="useTemplate" @refreshDataList="getFileList"></UseTemplate>
    </div>
</template>

<script>
    import AddCatalogueForm from './directoryForm/addCatalogueForm' // 新建目录弹窗
    import AddTemplate from './directoryForm/addTemplate' // 新建模板弹窗
    import UseTemplate from './directoryForm/useTemplate' // 套用模板弹窗

    export default {
        components: {AddCatalogueForm, AddTemplate, UseTemplate},
        data() {
            return {
                visibleLog: false,
                // 展览
                catalogueSrh: '',
                catalogueList: [],
                pageNo: 1,
                pageSize: 10,
                total: 0,
                // 文物清单
                fileSrh: '',
                fileList: [],
                dataListSelections: [],
                loading: false,
                pageNo2: 1,
                pageSize2: 10,
                total2: 0,
                exhibitionId: "",
            }
        },
        watch: {
            // 实时输入查询展览
            catalogueSrh() {
                this.searchCatalogue();
            },
        },
        mounted() {
            this.getCatalogue();
        },
        methods: {
            // 新建、编辑
            view(method, row) {
                if(method =="add" && this.exhibitionId == ""){
                    this.$message.error("请选择展览")
                    return false
                }
                this.$refs.addCatalogueForm.init(method, row)
            },
            // 目录表格多选
            selectionChangeHandle(val) {
                this.dataListSelections = val
            },
            // 设为模板
            addTemplate() {
                this.$refs.addTemplate.init(this.exhibitionId)
            },
            // 套用模板
            useTemplate() {
                if(this.fileList.length==0){
                    this.$refs.useTemplate.init(this.exhibitionId)
                }else{
                    this.$confirm(`确定替换所有目录吗?`, '提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning'
                    }).then(() => {
                        this.$refs.useTemplate.init(this.exhibitionId)
                    })
                }
            },
            //搜索展览
            searchCatalogue() {
                this.pageNo = 1;
                this.pageSize = 10;
                this.total = 0;
                this.exhibitionId = ""
                this.getCatalogue();
                // this.getFileList();
                this.fileList = [];
            },
            // 展览数据
            getCatalogue() {
                this.$axios(this.api.zlzs.queryExhibition, {
                    'name': this.catalogueSrh,
                    'pageNo': this.pageNo,
                    'pageSize': this.pageSize,
                }, 'get').then(data => {
                    if (data && data.status) {
                        this.catalogueList = data.data.records
                        this.total = parseInt(data.data.total)
                    }
                })
            },
            // 重置
            resetSearch() {
                this.pageNo2 = 1;
                this.pageSize2 = 10;
                this.total2 = 0;
                this.fileSrh = '';
                this.getFileList()
            },
            // 点击展览行
            handleCurrentChange(val) {
                this.$refs.catalogueTable.setCurrentRow(val);
                this.exhibitionId = val.id
                this.searchList()
            },
            searchList() {
                this.pageNo2 = 1;
                this.getFileList();
            },
            // 查询目录
            getFileList() {
                if(this.exhibitionId !== ''){
                    this.loading = true
                    this.$axios(this.api.zlzs.queryAllByLimit, {
                        'exhibitionId': this.exhibitionId,
                        "directoryName": this.fileSrh,
                        'pageNo': this.pageNo2,
                        'pageSize': this.pageSize2,
                    }, 'get').then(data => {
                        if (data && data.status) {
                            this.fileList = data.data.records
                            this.total2 = parseInt(data.data.total)
                            this.loading = false
                        }
                    })
                }
            },
            // 删除
            del(id) {
                this.$confirm(`确定删除所选项吗?`, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.$axios(this.api.zlzs.directoryRemoveById, {
                        'id': id,
                    }, 'delete').then(data => {
                        if(data && data.status){
                            this.$message.success(data.msg)
                            if(this.fileList.length==1){
                                this.pageNo2=1
                            }
                            this.getFileList()
                        }else{
                            this.$message.error(data.msg)
                        }
                    })
                })
            },
            // 展览每页数
            sizeChangeHandle(val) {
                this.pageSize = val;
                this.pageNo = 1;
                this.getCatalogue();
            },
            // 展览当前页
            currentChangeHandle(val) {
                this.pageNo = val;
                this.getCatalogue();
            },
            // 每页数
            sizeChangeHandle2(val) {
                this.pageSize2 = val;
                this.pageNo2 = 1;
                this.getFileList();
            },
            // 当前页
            currentChangeHandle2(val) {
                this.pageNo2 = val;
                this.getFileList();
            },
        }
    }
</script>

<style scoped>

</style>
